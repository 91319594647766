import React, { useState } from 'react';

const AuthContext = React.createContext();

function useAuth() {
  const context = React.useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}

function AuthProvider(props) {
  const [authTokens, setAuthTokens] = useState(localStorage.getItem('token'));

  const setTokens = (data) => {
    localStorage.setItem('token', data);
    setAuthTokens(data);
  };

  return (
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
      {props.children}
    </AuthContext.Provider>
  );
}

export { AuthProvider, useAuth };