import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Modal, Form, Icon, Table, Header, Segment, Grid, Image } from 'semantic-ui-react';
import { ChartCanvas, Chart } from 'react-stockcharts';
import { LineSeries } from 'react-stockcharts/lib/series';
import { XAxis, YAxis } from 'react-stockcharts/lib/axes';
import { MouseCoordinateX, MouseCoordinateY, CrossHairCursor } from 'react-stockcharts/lib/coordinates';
import { scaleTime } from 'd3-scale';
import { timeFormat } from 'd3-time-format';
import { format } from 'd3-format';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const CryptosPage = () => {
  const [cryptos, setCryptos] = useState([]);
  const [selectedCrypto, setSelectedCrypto] = useState(null);
  const [cryptoDetails, setCryptoDetails] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showSpendModal, setShowSpendModal] = useState(false);
  const [ticker, setTicker] = useState('');
  const [amount, setAmount] = useState(0);
  const [currentCryptoId, setCurrentCryptoId] = useState(null);
  const [currentAmount, setCurrentAmount] = useState(0);
  const [spendAmount, setSpendAmount] = useState(0);
  const [moneyIn, setMoneyIn] = useState(0);
  const [purchaseDate, setPurchaseDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [news, setNews] = useState([]);
  const [totalHoldingsValue, setTotalHoldingsValue] = useState(0);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState('max');
  const [currentPrice, setCurrentPrice] = useState(0);
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  const fetchCryptos = async () => {
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      };
      const response = await axiosInstance.get('/crypto', config);
      setCryptos(response.data);
      calculateTotalHoldingsValue(response.data);
      if (response.data.length > 0) {
        setSelectedCrypto(response.data[0].symbol);
      }
    } catch (error) {
      console.error('Error fetching cryptos:', error.response ? error.response.data : error.message);
    }
  };

  const fetchCryptoDetails = async (symbol) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      };

      const response = await axiosInstance.get(`/crypto/${symbol}/details`, config);
      const details = response.data;

      setCryptoDetails({
        _id: details._id || '',
        name: details.name || '',
        symbol: details.symbol || '',
        price: details.currentPrice || 0, // Use current price
        logo: details.logo || '',
        purchaseHistory: details.purchaseHistory || [],
      });

      setCurrentPrice(details.currentPrice || 0);

      const priceHistory = details.priceHistory.map((d) => ({
        date: new Date(d.date),
        close: d.price,
      }));
      setChartData(priceHistory);

      const newsResponse = await axiosInstance.get(
        `https://newsapi.org/v2/everything?q=${symbol}&apiKey=${process.env.REACT_APP_NEWS_API_KEY}`
      );
      setNews(newsResponse.data.articles);
    } catch (error) {
      console.error('Error fetching crypto details:', error.response ? error.response.data : error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchTickerSuggestions = async () => {
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      };

      const response = await axiosInstance.get('/crypto/suggestions', config);
      const bestMatches = response.data || [];
      setSuggestions(bestMatches);
    } catch (error) {
      console.error('Error fetching ticker suggestions:', error.response ? error.response.data : error.message);
      setSuggestions([]);
    }
  };

  const handleAddCrypto = async () => {
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      };

      const response = await axiosInstance.post('/crypto', { symbol: ticker, amount, moneyIn, purchaseDate }, config);
      setCryptos([...cryptos, response.data]);
      setShowAddModal(false);
      setTicker('');
      setAmount(0);
      setMoneyIn(0);
      setPurchaseDate('');
      calculateTotalHoldingsValue([...cryptos, response.data]);
    } catch (error) {
      console.error('Error adding crypto:', error.response ? error.response.data : error.message);
    }
  };

  const handleEditCrypto = async () => {
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      };

      const response = await axiosInstance.put(`/crypto/${currentCryptoId}`, { amount: currentAmount, moneyIn }, config);
      const updatedCryptos = cryptos.map((crypto) =>
        crypto._id === currentCryptoId ? response.data : crypto
      );
      setCryptos(updatedCryptos);
      setShowEditModal(false);
      calculateTotalHoldingsValue(updatedCryptos);
    } catch (error) {
      console.error('Error editing crypto:', error.response ? error.response.data : error.message);
    }
  };

  const handleDeleteCrypto = async (cryptoId) => {
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      };

      await axiosInstance.delete(`/crypto/${cryptoId}`, config);
      const updatedCryptos = cryptos.filter((crypto) => crypto._id !== cryptoId);
      setCryptos(updatedCryptos);
      calculateTotalHoldingsValue(updatedCryptos);
    } catch (error) {
      console.error('Error deleting crypto:', error.response ? error.response.data : error.message);
    }
  };

  const handleRemovePurchase = async (cryptoId, purchaseId) => {
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      };

      const response = await axiosInstance.put(`/crypto/${cryptoId}/remove-purchase/${purchaseId}`, {}, config);
      setCryptoDetails(response.data);
      setCurrentPrice(response.data.currentPrice || 0);
      fetchCryptos();
    } catch (error) {
      console.error('Error removing purchase:', error.response ? error.response.data : error.message);
    }
  };

  const handleSpendCoins = async () => {
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      };

      const response = await axiosInstance.put(`/crypto/spend/${currentCryptoId}`, { amount: spendAmount }, config);
      setCryptoDetails(response.data);
      setCurrentPrice(response.data.currentPrice || 0);
      setShowSpendModal(false);
      setSpendAmount(0);
      fetchCryptos();
    } catch (error) {
      console.error('Error spending coins:', error.response ? error.response.data : error.message);
    }
  };

  const calculateTotalHoldingsValue = (cryptos) => {
    const total = cryptos.reduce((sum, crypto) => sum + (crypto.currentPrice || 0) * (crypto.totalAmount || 0), 0);
    setTotalHoldingsValue(total);
  };

  const filteredChartData = () => {
    const now = new Date();
    let startDate;

    switch (selectedPeriod) {
      case 'max':
        return chartData;
      case '5y':
        startDate = new Date(now.getFullYear() - 5, now.getMonth(), now.getDate());
        break;
      case '1y':
        startDate = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
        break;
      case 'ytd':
        startDate = new Date(now.getFullYear(), 0, 1);
        break;
      case '1m':
        startDate = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
        break;
      case '1w':
        startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
        break;
      default:
        return chartData;
    }

    return chartData.filter((d) => d.date >= startDate);
  };

  const percentageChangeForChart = () => {
    if (chartDataToShow.length < 2) return 0;
    const firstPrice = chartDataToShow[0].close;
    const lastPrice = chartDataToShow[chartDataToShow.length - 1].close;
    return ((lastPrice - firstPrice) / firstPrice) * 100;
  };

  const onTickerChange = (e) => {
    setTicker(e.target.value);
  };

  useEffect(() => {
    fetchCryptos();
    fetchTickerSuggestions();
  }, []);

  useEffect(() => {
    if (selectedCrypto) {
      fetchCryptoDetails(selectedCrypto);
    }
  }, [selectedCrypto]);

  const chartDataToShow = filteredChartData();

  return (
    <div className="ui container" style={{ marginTop: '70px' }}>
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header as="h2">Total Holdings: £{totalHoldingsValue.toFixed(2)} </Header>
            <Button primary onClick={() => setShowAddModal(true)}>
              Add Crypto
            </Button>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          {cryptos.map((crypto) => (
            <Grid.Column
              key={crypto._id}
              width={5}
              className={`ui card ${selectedCrypto === crypto.symbol ? 'blue inverted' : ''}`}
              onClick={() => setSelectedCrypto(crypto.symbol)}
            >
              <div className="content">
                <Image floated="right" size="mini" src={crypto.logo} />
                <div className="header">{crypto.symbol}</div>
                <div className="meta">
                  {crypto.totalAmount >= 1
                    ? crypto.totalAmount.toFixed(2)
                    : (crypto.totalAmount * 1000000).toFixed(0) + ' Satoshi'}{' '}
                  {crypto.totalAmount >= 1 ? 'coins' : ''}
                </div>
                <div className="description">
                  £{((crypto.currentPrice || 0) * (crypto.totalAmount || 0)).toFixed(2)}
                </div>
                <div
                  className={`description ${crypto.currentPrice - crypto.averagePrice >= 0 ? 'green' : 'red'}`}
                >
                  {crypto.averagePrice
                    ? ((crypto.currentPrice - crypto.averagePrice) / crypto.averagePrice).toFixed(3) * 100
                    : 0}
                  %
                </div>
              </div>
              <div className="extra content">
                <div className="ui three buttons">
                  <Button
                    basic
                    color="grey"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowEditModal(true);
                      setCurrentCryptoId(crypto._id);
                      setCurrentAmount(crypto.totalAmount);
                      setMoneyIn(crypto.moneyIn);
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    basic
                    color="red"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeleteCrypto(crypto._id);
                    }}
                  >
                    Delete
                  </Button>
                  <Button
                    basic
                    color="yellow"
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowSpendModal(true);
                      setCurrentCryptoId(crypto._id);
                    }}
                  >
                    Spend
                  </Button>
                </div>
              </div>
            </Grid.Column>
          ))}
        </Grid.Row>

        {/* Chart */}
        <Grid.Row>
          <Grid.Column width={16}>
            {loading ? (
              <Segment loading />
            ) : (
              cryptoDetails && (
                <Segment>
                  <Header as="h3">
                    {cryptoDetails.name} ({cryptoDetails.symbol})
                  </Header>
                  <Header as="h4">Current Price: £{currentPrice.toFixed(2)}</Header>
                  <Header as="h5">
                    Percentage Change for Chart: {percentageChangeForChart().toFixed(2)}%
                  </Header>
                  <div className="chart-container">
                    <ChartCanvas
                      height={300}
                      width={Math.min(windowDimensions.width - 64, 1100)} // Adjust width based on screen width
                      margin={{ left: 50, right: 50, top: 10, bottom: 30 }}
                      ratio={3}
                      seriesName="Crypto"
                      data={chartDataToShow}
                      xScale={scaleTime()}
                      xAccessor={(d) => d.date}
                      xExtents={[chartDataToShow[0]?.date, chartDataToShow[chartDataToShow.length - 1]?.date]}
                    >
                      <Chart id={0} yExtents={(d) => d.close}>
                        <XAxis axisAt="bottom" orient="bottom" ticks={6} />
                        <YAxis axisAt="left" orient="left" />
                        <MouseCoordinateX
                          at="bottom"
                          orient="bottom"
                          displayFormat={timeFormat('%Y-%m-%d')}
                        />
                        <MouseCoordinateY
                          at="left"
                          orient="left"
                          displayFormat={format('.2f')}
                        />
                        <LineSeries yAccessor={(d) => d.close} />
                        <CrossHairCursor />
                      </Chart>
                    </ChartCanvas>
                  </div>
                  <div style={{width : "Math.min(windowDimensions.width - 64, 1100)px"}}>
                  <Button.Group  fluid widths={6}>
                    <Button onClick={() => setSelectedPeriod('max')}>Max</Button>
                    <Button onClick={() => setSelectedPeriod('5y')}>5Y</Button>
                    <Button onClick={() => setSelectedPeriod('1y')}>1Y</Button>
                    <Button onClick={() => setSelectedPeriod('ytd')}>YTD</Button>
                    <Button onClick={() => setSelectedPeriod('1m')}>1M</Button>
                    <Button onClick={() => setSelectedPeriod('1w')}>1W</Button>
                  </Button.Group>
                  </div>
                </Segment>
              )
            )}
          </Grid.Column>
        </Grid.Row>

        {/* Purchase History */}
        <Grid.Row>
          <Grid.Column width={16}>
            {cryptoDetails && (
              <Segment>
                <Header as="h5">Purchase History</Header>
                <Table celled>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>Symbol</Table.HeaderCell>
                      <Table.HeaderCell>Amount</Table.HeaderCell>
                      <Table.HeaderCell>Price Paid</Table.HeaderCell>
                      <Table.HeaderCell>Date Purchased</Table.HeaderCell>
                      <Table.HeaderCell>Actions</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    {cryptoDetails.purchaseHistory.map((purchase, index) => (
                      <Table.Row key={index}>
                        <Table.Cell>{cryptoDetails.symbol}</Table.Cell>
                        <Table.Cell>{purchase.amount}</Table.Cell>
                        <Table.Cell>£{purchase.price.toFixed(2)}</Table.Cell>
                        <Table.Cell>{new Date(purchase.date).toLocaleDateString()}</Table.Cell>
                        <Table.Cell>
                          <Button basic color="red" onClick={() => handleRemovePurchase(cryptoDetails._id, purchase._id)}>
                            Remove
                          </Button>
                        </Table.Cell>
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
              </Segment>
            )}
          </Grid.Column>
        </Grid.Row>

        {/* Latest News */}
        <Grid.Row>
          <Grid.Column width={16}>
            {cryptoDetails && (
              <Segment>
                <Header as="h5">Latest News</Header>
                <div className="ui list">
                  {news.map((article, index) => (
                    <div className="item" key={index}>
                      <div className="content">
                        <a className="header" href={article.url} target="_blank" rel="noopener noreferrer">
                          {article.title}
                        </a>
                        <div className="description">{new Date(article.publishedAt).toLocaleDateString()}</div>
                      </div>
                    </div>
                  ))}
                </div>
              </Segment>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>

{/* Add Crypto Modal */}
<Modal open={showAddModal} onClose={() => setShowAddModal(false)} closeIcon>
  <Header icon="archive" content="Add Crypto" />
  <Modal.Content>
    <Form>
      <Form.Field>
        <label>Symbol</label>
        <select value={ticker} onChange={onTickerChange} required>
          <option value="" disabled>
            Select crypto
          </option>
          {suggestions.map((crypto, index) => (
            <option key={index} value={crypto.symbol}>
              {crypto.name} ({crypto.symbol})
            </option>
          ))}
        </select>
      </Form.Field>
      <Form.Field>
        <label>Amount</label>
        <input
          type="number"
          step="0.00000001"
          value={amount}
          onChange={(e) => setAmount(parseFloat(e.target.value))}
          required
        />
      </Form.Field>
      <Form.Field>
        <label>Money Put In</label>
        <input
          type="number"
          step="0.01"
          value={moneyIn}
          onChange={(e) => setMoneyIn(parseFloat(e.target.value))}
          required
        />
      </Form.Field>
      <Form.Field>
        <label>Purchase Date</label>
        <input
          type="date"
          value={purchaseDate}
          onChange={(e) => setPurchaseDate(e.target.value)}
          required
        />
      </Form.Field>
    </Form>
  </Modal.Content>
  <Modal.Actions>
    <Button color="grey" onClick={() => setShowAddModal(false)}>
      Cancel
    </Button>
    <Button color="green" onClick={handleAddCrypto}>
      Add
    </Button>
  </Modal.Actions>
</Modal>

{/* Edit Crypto Modal */}
<Modal open={showEditModal} onClose={() => setShowEditModal(false)} closeIcon>
  <Header icon="archive" content="Edit Crypto" />
  <Modal.Content>
    <Form>
      <Form.Field>
        <label>Amount</label>
        <input
          type="number"
          step="0.00000001"
          value={currentAmount}
          onChange={(e) => setCurrentAmount(parseFloat(e.target.value))}
          required
        />
      </Form.Field>
      <Form.Field>
        <label>Money Put In</label>
        <input
          type="number"
          step="0.01"
          value={moneyIn}
          onChange={(e) => setMoneyIn(parseFloat(e.target.value))}
          required
        />
      </Form.Field>
    </Form>
  </Modal.Content>
  <Modal.Actions>
    <Button color="grey" onClick={() => setShowEditModal(false)}>
      Cancel
    </Button>
    <Button color="green" onClick={handleEditCrypto}>
      Save
    </Button>
  </Modal.Actions>
</Modal>

{/* Spend Crypto Modal */}
<Modal open={showSpendModal} onClose={() => setShowSpendModal(false)} closeIcon>
  <Header icon="archive" content="Spend Crypto" />
  <Modal.Content>
    <Form>
      <Form.Field>
        <label>Amount to Spend</label>
        <input
          type="number"
          step="0.00000001"
          value={spendAmount}
          onChange={(e) => setSpendAmount(parseFloat(e.target.value))}
          required
        />
      </Form.Field>
    </Form>
  </Modal.Content>
  <Modal.Actions>
    <Button color="grey" onClick={() => setShowSpendModal(false)}>
      Cancel
    </Button>
    <Button color="green" onClick={handleSpendCoins}>
      Spend
    </Button>
  </Modal.Actions>
</Modal>

    </div>
  );
};

export default CryptosPage;
