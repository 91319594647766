import React, { useEffect, useState } from 'react';
import { Container, Header, Segment, Message } from 'semantic-ui-react';

const Dashboard = () => {
  const [quote, setQuote] = useState('');
  const isAuthenticated = !!localStorage.getItem('token');

  useEffect(() => {
    fetch('https://api.quotable.io/random')
      .then(response => response.json())
      .then(data => setQuote(data.content))
      .catch(error => console.error('Error fetching quote:', error));
  }, []);

  return (
    <Container className="dashboard-page ui container" style={{ marginTop: '70px' }}>
      <Segment textAlign="center">
        {isAuthenticated ? (
          <>
            <Header as="h1">Welcome to Your Dashboard</Header>
            <p>This is your personal dashboard where you can navigate to different sections.</p>
          </>
        ) : (
          <>
            <Header as="h1">Welcome to Emrani</Header>
            <p>Please login or register to access your personal dashboard.</p>
          </>
        )}
        <Message as="h1">
          <blockquote>{quote}</blockquote>
        </Message>
      </Segment>
    </Container>
  );
};

export default Dashboard;