import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Menu, Icon, Container, Button, Sidebar, Segment } from 'semantic-ui-react';
import logo from './logo.webp';

const Navbar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const isAuthenticated = !!localStorage.getItem('token');

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  return (
    <Segment inverted>
      <Menu fixed="top" inverted>
        <Container>
          <Menu.Item as={Link} to="/" header>
            <img src={logo} alt="Emrani Logo" style={{ marginRight: '1.5em', height: '40px' }} />
          </Menu.Item>
          <Menu.Item position="right" onClick={handleMenuToggle}>
            <Icon name="sidebar" />
          </Menu.Item>
        </Container>
      </Menu>

      <Sidebar
        as={Menu}
        animation="overlay"
        icon="labeled"
        inverted
        onHide={() => setIsMenuOpen(false)}
        vertical
        visible={isMenuOpen}
        width="thin"
      >
        <Menu.Item as={Link} to="/" onClick={() => setIsMenuOpen(false)}>
          Dashboard
        </Menu.Item>
        {isAuthenticated ? (
          <>
            <Menu.Item as={Link} to="/personal-info" onClick={() => setIsMenuOpen(false)}>
              Personal Info
            </Menu.Item>
            <Menu.Item as={Link} to="/email" onClick={() => setIsMenuOpen(false)}>
              Email
            </Menu.Item>
            <Menu.Item as={Link} to="/finance" onClick={() => setIsMenuOpen(false)}>
              Finance
            </Menu.Item>
            <Menu.Item as={Link} to="/notes" onClick={() => setIsMenuOpen(false)}>
              Notes
            </Menu.Item>
            <Menu.Item as={Link} to="/stocks" onClick={() => setIsMenuOpen(false)}>
              Stocks
            </Menu.Item>
            <Menu.Item as={Link} to="/crypto" onClick={() => setIsMenuOpen(false)}>
              Crypto
            </Menu.Item>
            <Menu.Item onClick={handleLogout}>
              Logout
            </Menu.Item>
          </>
        ) : (
          <>
            <Menu.Item as={Link} to="/login" onClick={() => setIsMenuOpen(false)}>
              Login
            </Menu.Item>
            <Menu.Item as={Link} to="/register" onClick={() => setIsMenuOpen(false)}>
              Register
            </Menu.Item>
          </>
        )}
      </Sidebar>
    </Segment>
  );
};

export default Navbar;
