import React, { useState, useEffect } from 'react';
import FileViewer from 'react-file-viewer';
import { Container, Button, Modal, Form, Grid, Segment, Header, Icon, Input, TextArea } from 'semantic-ui-react';
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const NotesPage = () => {
  const [notes, setNotes] = useState([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showNoteModal, setShowNoteModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentNote, setCurrentNote] = useState(null);
  const [title, setTitle] = useState('');
  const [content, setContent] = useState('');
  const [attachment, setAttachment] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [deleteNoteId, setDeleteNoteId] = useState(null);
  const [attachmentUrl, setAttachmentUrl] = useState('');
  const [attachmentType, setAttachmentType] = useState('');
  const [viewingAttachment, setViewingAttachment] = useState(false);

  useEffect(() => {
    const fetchNotes = async () => {
      try {
        const token = localStorage.getItem('token');
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': token,
          },
        };

        const response = await axiosInstance.get(`/notes`, config);
        setNotes(response.data);
      } catch (error) {
        console.error('Error fetching notes:', error);
      }
    };

    fetchNotes();
  }, []);

  const handleCreateNote = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('title', title);
    formData.append('content', content);
    if (attachment) {
      formData.append('attachment', attachment);
    }

    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-auth-token': token,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        },
      };

      const response = await axiosInstance.post(`/notes`, formData, config);
      setNotes([response.data, ...notes]);
      setShowCreateModal(false);
      setTitle('');
      setContent('');
      setAttachment(null);
      setUploadProgress(0);
    } catch (error) {
      console.error('Error creating note:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleEditNote = async () => {
    setLoading(true);
    const formData = new FormData();
    formData.append('title', title);
    formData.append('content', content);
    if (attachment) {
      formData.append('attachment', attachment);
    }

    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-auth-token': token,
        },
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        },
      };

      const response = await axiosInstance.put(`/notes/${currentNote._id}`, formData, config);
      setNotes(notes.map(note => note._id === currentNote._id ? response.data : note));
      setShowEditModal(false);
      setCurrentNote(null);
      setTitle('');
      setContent('');
      setAttachment(null);
      setUploadProgress(0);
    } catch (error) {
      console.error('Error editing note:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDeleteNote = async () => {
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      };

      await axiosInstance.delete(`/notes/${deleteNoteId}`, config);
      setNotes(notes.filter(note => note._id !== deleteNoteId));
      setShowDeleteModal(false);
      setDeleteNoteId(null);
    } catch (error) {
      console.error('Error deleting note:', error);
    }
  };

  const handleViewAttachment = async (attachment) => {
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'x-auth-token': token,
        },
        responseType: 'blob',
      };

      const response = await axiosInstance.get(`/notes/attachment/${attachment.split('/').pop()}`, config);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const fileType = attachment.split('.').pop();

      setAttachmentUrl(url);
      setAttachmentType(fileType);
      setViewingAttachment(true);
    } catch (error) {
      console.error('Error viewing attachment:', error);
    }
  };

  const handleDownloadAttachment = async (attachment) => {
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'x-auth-token': token,
        },
        responseType: 'blob',
      };

      const response = await axiosInstance.get(`/notes/attachment/${attachment.split('/').pop()}`, config);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', attachment.split('/').pop());
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error downloading attachment:', error);
    }
  };

  const renderAttachment = () => {
    if (!attachmentUrl) return null;

    return (
      <div className="file-viewer-container">
        <FileViewer
          fileType={attachmentType}
          filePath={attachmentUrl}
          onError={console.error}
        />
      </div>
    );
  };

  return (
    <Container className="notes-page" style={{ marginTop: '70px' }}>
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <Button primary onClick={() => setShowCreateModal(true)}>
              Create Note
            </Button>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          {notes.map((note) => (
            <Grid.Column
              key={note._id}
              width={5}
              className={`ui card`}
              onClick={() => { setCurrentNote(note); setShowNoteModal(true); }}
            >
              <div className="content">
                <Header as="h3">{note.title}</Header>
                <p>{note.content}</p>
                {note.attachment && (
                  <div className="attachment-info-wrapper">
                    <span>Attachment: {note.attachment.split('/').pop()}</span>
                    <span className="attachment-size">{note.attachmentSize ? Number(note.attachmentSize).toFixed(2) : 0} MB</span>
                  </div>
                )}
              </div>
              <div className="extra content">
                <div className="ui two buttons">
                  <Button
                    basic
                    color="grey"
                    onClick={(e) => {
                      e.stopPropagation();
                      setCurrentNote(note);
                      setTitle(note.title);
                      setContent(note.content);
                      setShowEditModal(true);
                    }}
                  >
                    Edit
                  </Button>
                  <Button
                    basic
                    color="red"
                    onClick={(e) => {
                      e.stopPropagation();
                      setDeleteNoteId(note._id);
                      setShowDeleteModal(true);
                    }}
                  >
                    Delete
                  </Button>
                </div>
              </div>
            </Grid.Column>
          ))}
        </Grid.Row>

        {/* Create Note Modal */}
        <Modal open={showCreateModal} onClose={() => setShowCreateModal(false)} size="small">
          <Header icon="pencil" content="Create Note" />
          <Modal.Content>
            <Form>
              <Form.Field>
                <label>Title</label>
                <Input
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
              </Form.Field>
              <Form.Field>
                <label>Content</label>
                <TextArea
                  rows={3}
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  required
                />
              </Form.Field>
              <Form.Field>
                <label>Attachment</label>
                <Input type="file" onChange={(e) => setAttachment(e.target.files[0])} />
              </Form.Field>
              {loading && (
                <div className="loader-wrapper">
                  <div className="ui active inline loader"></div>
                  <p>{uploadProgress}%</p>
                </div>
              )}
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => setShowCreateModal(false)}>
              <Icon name="remove" /> Cancel
            </Button>
            <Button primary onClick={handleCreateNote} disabled={loading}>
              <Icon name="checkmark" /> Create
            </Button>
          </Modal.Actions>
        </Modal>

        {/* View Note Modal */}
        {currentNote && (
          <Modal open={showNoteModal} onClose={() => setShowNoteModal(false)} size="small">
            <Header icon="book" content={currentNote.title} />
            <Modal.Content>
              <p>{currentNote.content}</p>
              {currentNote.attachment && (
                <div className="attachment-wrapper">
                  <Button onClick={() => handleViewAttachment(currentNote.attachment)}>
                    View Attachment
                  </Button>
                  <span className="attachment-size">{currentNote.attachmentSize ? Number(currentNote.attachmentSize).toFixed(2) : 0} MB</span>
                </div>
              )}
            </Modal.Content>
          </Modal>
        )}

        {/* Edit Note Modal */}
        <Modal open={showEditModal} onClose={() => setShowEditModal(false)} size="small">
          <Header icon="pencil" content="Edit Note" />
          <Modal.Content>
            <Form>
              <Form.Field>
                <label>Title</label>
                <Input
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  required
                />
              </Form.Field>
              <Form.Field>
                <label>Content</label>
                <TextArea
                  rows={3}
                  value={content}
                  onChange={(e) => setContent(e.target.value)}
                  required
                />
              </Form.Field>
              <Form.Field>
                <label>Attachment</label>
                <Input type="file" onChange={(e) => setAttachment(e.target.files[0])} />
              </Form.Field>
              {loading && (
                <div className="loader-wrapper">
                  <div className="ui active inline loader"></div>
                  <p>{uploadProgress}%</p>
                </div>
              )}
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => setShowEditModal(false)}>
              <Icon name="remove" /> Cancel
            </Button>
            <Button primary onClick={handleEditNote} disabled={loading}>
              <Icon name="checkmark" /> Save
            </Button>
          </Modal.Actions>
        </Modal>

        {/* Delete Confirmation Modal */}
        <Modal open={showDeleteModal} onClose={() => setShowDeleteModal(false)} size="small">
          <Header icon="trash" content="Confirm Deletion" />
          <Modal.Content>
            <p>Are you sure you want to delete this note?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => setShowDeleteModal(false)}>
              <Icon name="remove" /> Cancel
            </Button>
            <Button primary onClick={handleDeleteNote}>
              <Icon name="checkmark" /> Delete
            </Button>
          </Modal.Actions>
        </Modal>

        {/* View Attachment Modal */}
        {viewingAttachment && (
          <Modal open={viewingAttachment} onClose={() => setViewingAttachment(false)} size="large">
            <Header icon="file" content="Attachment" />
            <Modal.Content className="attachment-modal-body">
              {renderAttachment()}
              <Button primary onClick={() => handleDownloadAttachment(currentNote.attachment)} className="download-button">
                <Icon name="download" /> Download
              </Button>
            </Modal.Content>
          </Modal>
        )}
      </Grid>
    </Container>
  );
};

export default NotesPage;
