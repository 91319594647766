import React, { useState, useEffect } from 'react';
import { ChartCanvas, Chart } from 'react-stockcharts';
import { LineSeries } from 'react-stockcharts/lib/series';
import { XAxis, YAxis } from 'react-stockcharts/lib/axes';
import { MouseCoordinateX, MouseCoordinateY, CrossHairCursor } from 'react-stockcharts/lib/coordinates';
import { scaleTime } from 'd3-scale';
import { timeFormat } from 'd3-time-format';
import { format } from 'd3-format';
import Autosuggest from 'react-autosuggest';
import { Container, Button, Modal, Form, Grid, Segment, Header, Icon, Table, Input, Image } from 'semantic-ui-react';
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const StocksPage = () => {
  const [stocks, setStocks] = useState([]);
  const [selectedStock, setSelectedStock] = useState(null);
  const [stockDetails, setStockDetails] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [ticker, setTicker] = useState('');
  const [shares, setShares] = useState(0);
  const [currentStockId, setCurrentStockId] = useState(null);
  const [currentShares, setCurrentShares] = useState(0);
  const [moneyIn, setMoneyIn] = useState(0);
  const [purchaseDate, setPurchaseDate] = useState('');
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [news, setNews] = useState([]);
  const [totalHoldingsValue, setTotalHoldingsValue] = useState(0);
  const [suggestions, setSuggestions] = useState([]);
  const [selectedPeriod, setSelectedPeriod] = useState('max');
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchStocks = async () => {
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      };
      const response = await axiosInstance.get(`/stocks`, config);
      setStocks(response.data);
      calculateTotalHoldingsValue(response.data);
      if (response.data.length > 0) {
        setSelectedStock(response.data[0].ticker);
      }
    } catch (error) {
      console.error('Error fetching stocks:', error.response ? error.response.data : error.message);
    }
  };

  const fetchStockDetails = async (ticker) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      };

      const response = await axiosInstance.get(`/stocks/${ticker}/details`, config);
      const details = response.data;

      setStockDetails({
        _id: details._id,
        name: details.name,
        ticker: details.ticker,
        price: details.price,
        currency: details.currency,
        exchange: details.exchange,
        logo: details.logo,
        purchaseHistory: details.purchaseHistory || [],
      });

      const priceHistory = details.priceHistory.map(d => ({
        date: new Date(d.date),
        close: d.price,
      }));
      setChartData(priceHistory);

      const newsResponse = await axiosInstance.get(`https://newsapi.org/v2/everything?q=${ticker}&apiKey=${process.env.REACT_APP_NEWS_API_KEY}`);
      setNews(newsResponse.data.articles);
    } catch (error) {
      console.error('Error fetching stock details:', error.response ? error.response.data : error.message);
    } finally {
      setLoading(false);
    }
  };

  const fetchTickerSuggestions = async (value) => {
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      };
      const response = await axiosInstance.get(`/stocks/suggestions/${value}`, config);
      const bestMatches = response.data || [];
      setSuggestions(bestMatches.map(match => ({ ticker: match.symbol, name: match.name })));
    } catch (error) {
      console.error('Error fetching ticker suggestions:', error.response ? error.response.data : error.message);
      setSuggestions([]);
    }
  };

  const handleAddStock = async () => {
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      };

      const response = await axiosInstance.post(`/stocks`, { ticker, shares, moneyIn, purchaseDate }, config);
      setStocks([...stocks, response.data]);
      setShowAddModal(false);
      setTicker('');
      setShares(0);
      setMoneyIn(0);
      setPurchaseDate('');
      calculateTotalHoldingsValue([...stocks, response.data]);
    } catch (error) {
      console.error('Error adding stock:', error.response ? error.response.data : error.message);
    }
  };

  const handleEditStock = async () => {
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      };

      const response = await axiosInstance.put(`/stocks/${currentStockId}`, { shares: currentShares, moneyIn }, config);
      const updatedStocks = stocks.map(stock => stock._id === currentStockId ? response.data : stock);
      setStocks(updatedStocks);
      setShowEditModal(false);
      calculateTotalHoldingsValue(updatedStocks);
    } catch (error) {
      console.error('Error editing stock:', error.response ? error.response.data : error.message);
    }
  };

  const handleDeleteStock = async (stockId) => {
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      };

      await axiosInstance.delete(`/stocks/${stockId}`, config);
      const updatedStocks = stocks.filter(stock => stock._id !== stockId);
      setStocks(updatedStocks);
      calculateTotalHoldingsValue(updatedStocks);
    } catch (error) {
      console.error('Error deleting stock:', error.response ? error.response.data : error.message);
    }
  };

  const handleRemovePurchase = async (stockId, purchaseId) => {
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      };

      const response = await axiosInstance.put(`/stocks/${stockId}/remove-purchase/${purchaseId}`, {}, config);
      setStockDetails(response.data);
      fetchStocks();
    } catch (error) {
      console.error('Error removing purchase:', error.response ? error.response.data : error.message);
    }
  };

  const calculateTotalHoldingsValue = (stocks) => {
    const total = stocks.reduce((sum, stock) => sum + (stock.price || 0) * (stock.shares || 0) * (stock.exchangeRate || 1), 0);
    setTotalHoldingsValue(total);
  };

  const filteredChartData = () => {
    const now = new Date();
    let startDate;

    switch (selectedPeriod) {
      case 'max':
        return chartData;
      case '5y':
        startDate = new Date(now.getFullYear() - 5, now.getMonth(), now.getDate());
        break;
      case '1y':
        startDate = new Date(now.getFullYear() - 1, now.getMonth(), now.getDate());
        break;
      case 'ytd':
        startDate = new Date(now.getFullYear(), 0, 1);
        break;
      case '1m':
        startDate = new Date(now.getFullYear(), now.getMonth() - 1, now.getDate());
        break;
      case '1w':
        startDate = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
        break;
      default:
        return chartData;
    }

    return chartData.filter(d => d.date >= startDate);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    fetchTickerSuggestions(value);
  };

  const onSuggestionsClearRequested = () => {
    setSuggestions([]);
  };

  const getSuggestionValue = (suggestion) => suggestion.ticker;

  const renderSuggestion = (suggestion) => (
    <div>
      {suggestion.name} ({suggestion.ticker})
    </div>
  );

  const inputProps = {
    placeholder: 'Enter stock ticker',
    value: ticker,
    onChange: (e, { newValue }) => setTicker(newValue),
  };

  useEffect(() => {
    fetchStocks();
  }, []);

  useEffect(() => {
    if (selectedStock) {
      fetchStockDetails(selectedStock);
    }
  }, [selectedStock]);

  const chartDataToShow = filteredChartData();

  const percentageChange = () => {
    if (chartDataToShow.length < 2) return 0;
    const firstPrice = chartDataToShow[0].close;
    const lastPrice = chartDataToShow[chartDataToShow.length - 1].close;
    return ((lastPrice - firstPrice) / firstPrice) * 100;
  };

  return (
    <Container fluid className="stocks-page" style={{ marginTop: '70px' }}>
<Grid>
  <Grid.Row>
    <Grid.Column width={16}>
      <Header as="h2">Total Holdings: £{totalHoldingsValue.toFixed(2)}</Header>
      <Button primary onClick={() => setShowAddModal(true)}>
        Add Stock
      </Button>
    </Grid.Column>
  </Grid.Row>

  <Grid.Row>
    {stocks.map((stock) => (
      <Grid.Column
        key={stock._id}
        width={5}
        className={`ui card ${selectedStock === stock.ticker ? 'blue inverted' : ''}`}
        onClick={() => setSelectedStock(stock.ticker)}
      >
        <div className="content">
          <Image floated="right" size="mini" src={stock.logo} />
          <div className="header">{stock.ticker}</div>
          <div className="meta">
            {stock.shares.toFixed(2)} shares
          </div>
          <div className="description">
            £{(stock.price * stock.shares * stock.exchangeRate).toFixed(2)}
          </div>
          <div
            className={`description ${stock.price * stock.exchangeRate >= (stock.moneyIn / stock.shares) ? 'green' : 'red'}`}
          >
            {Math.round(stock.price * stock.exchangeRate >= (stock.moneyIn / stock.shares) ? (((stock.price * stock.exchangeRate) - (stock.moneyIn / stock.shares)) / stock.moneyIn * 100) : (-(1 - ((stock.price * stock.exchangeRate) / (stock.moneyIn / stock.shares))) * 100))}%
          </div>
        </div>
        <div className="extra content">
          <div className="ui three buttons">
            <Button
              basic
              color="grey"
              onClick={(e) => {
                e.stopPropagation();
                setShowEditModal(true);
                setCurrentStockId(stock._id);
                setCurrentShares(stock.shares);
                setMoneyIn(stock.moneyIn);
              }}
            >
              Edit
            </Button>
            <Button
              basic
              color="red"
              onClick={(e) => {
                e.stopPropagation();
                handleDeleteStock(stock._id);
              }}
            >
              Delete
            </Button>
          </div>
        </div>
      </Grid.Column>
    ))}
  </Grid.Row>

  {/* Chart */}
  <Grid.Row>
    <Grid.Column width={16}>
      {loading ? (
        <Segment loading />
      ) : (
        stockDetails && (
          <Segment>
            <Header as="h3">
              {stockDetails.name} ({stockDetails.ticker})
            </Header>
            <Header as="h4">Current Price: {stockDetails.currency === 'GBP' ? '£' : '$'}{stockDetails.price.toFixed(2)}</Header>
            <Header as="h5">
              Percentage Change for Chart: {percentageChange().toFixed(2)}%
            </Header>
            <div className="chart-container">
              <ChartCanvas
                height={300}
                width={Math.min(windowDimensions.width - 64, 3000)} // Adjust width based on screen width
                margin={{ left: 50, right: 50, top: 10, bottom: 30 }}
                ratio={3}
                seriesName="Stock"
                data={chartDataToShow}
                xScale={scaleTime()}
                xAccessor={(d) => d.date}
                xExtents={[chartDataToShow[0]?.date, chartDataToShow[chartDataToShow.length - 1]?.date]}
              >
                <Chart id={0} yExtents={(d) => d.close}>
                  <XAxis axisAt="bottom" orient="bottom" ticks={6} />
                  <YAxis axisAt="left" orient="left" />
                  <MouseCoordinateX
                    at="bottom"
                    orient="bottom"
                    displayFormat={timeFormat('%Y-%m-%d')}
                  />
                  <MouseCoordinateY
                    at="left"
                    orient="left"
                    displayFormat={format('.2f')}
                  />
                  <LineSeries yAccessor={(d) => d.close} />
                  <CrossHairCursor />
                </Chart>
              </ChartCanvas>
            </div>
            <Button.Group fluid widths={6}>
              <Button onClick={() => setSelectedPeriod('max')}>Max</Button>
              <Button onClick={() => setSelectedPeriod('5y')}>5Y</Button>
              <Button onClick={() => setSelectedPeriod('1y')}>1Y</Button>
              <Button onClick={() => setSelectedPeriod('ytd')}>YTD</Button>
              <Button onClick={() => setSelectedPeriod('1m')}>1M</Button>
              <Button onClick={() => setSelectedPeriod('1w')}>1W</Button>
            </Button.Group>
          </Segment>
        )
      )}
    </Grid.Column>
  </Grid.Row>

  {/* Purchase History */}
  <Grid.Row>
    <Grid.Column width={16}>
      {stockDetails && (
        <Segment>
          <Header as="h5">Purchase History</Header>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Symbol</Table.HeaderCell>
                <Table.HeaderCell>Amount</Table.HeaderCell>
                <Table.HeaderCell>Price Paid</Table.HeaderCell>
                <Table.HeaderCell>Date Purchased</Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {stockDetails.purchaseHistory.map((purchase, index) => (
                <Table.Row key={index}>
                  <Table.Cell>{stockDetails.ticker}</Table.Cell>
                  <Table.Cell>{purchase.shares}</Table.Cell>
                  <Table.Cell>£{purchase.moneyIn.toFixed(2)}</Table.Cell>
                  <Table.Cell>{new Date(purchase.date).toLocaleDateString()}</Table.Cell>
                  <Table.Cell>
                    <Button basic color="red" onClick={() => handleRemovePurchase(stockDetails._id, purchase._id)}>
                      Remove
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Segment>
      )}
    </Grid.Column>
  </Grid.Row>

  {/* Latest News */}
  <Grid.Row>
    <Grid.Column width={16}>
      {stockDetails && (
        <Segment>
          <Header as="h5">Latest News</Header>
          <div className="ui list">
            {news.map((article, index) => (
              <div className="item" key={index}>
                <div className="content">
                  <a className="header" href={article.url} target="_blank" rel="noopener noreferrer">
                    {article.title}
                  </a>
                  <div className="description">{new Date(article.publishedAt).toLocaleDateString()}</div>
                </div>
              </div>
            ))}
          </div>
        </Segment>
      )}
    </Grid.Column>
  </Grid.Row>
</Grid>

      {/* Add Stock Modal */}
      <Modal open={showAddModal} onClose={() => setShowAddModal(false)} size="small">
        <Header icon="plus" content="Add Stock" />
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>Ticker</label>
              <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                onSuggestionsClearRequested={onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
              />
            </Form.Field>
            <Form.Field>
              <label>Shares</label>
              <Input
                type="number"
                step="0.01"
                value={shares}
                onChange={(e) => setShares(parseFloat(e.target.value))}
                required
              />
            </Form.Field>
            <Form.Field>
              <label>Money Put In</label>
              <Input
                type="number"
                step="0.01"
                value={moneyIn}
                onChange={(e) => setMoneyIn(parseFloat(e.target.value))}
                required
              />
            </Form.Field>
            <Form.Field>
              <label>Purchase Date</label>
              <Input
                type="date"
                value={purchaseDate}
                onChange={(e) => setPurchaseDate(e.target.value)}
                required
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setShowAddModal(false)}>
            <Icon name="remove" /> Cancel
          </Button>
          <Button primary onClick={handleAddStock}>
            <Icon name="checkmark" /> Add
          </Button>
        </Modal.Actions>
      </Modal>

      {/* Edit Stock Modal */}
      <Modal open={showEditModal} onClose={() => setShowEditModal(false)} size="small">
        <Header icon="pencil" content="Edit Stock" />
        <Modal.Content>
          <Form>
            <Form.Field>
              <label>Shares</label>
              <Input
                type="number"
                step="0.01"
                value={currentShares}
                onChange={(e) => setCurrentShares(parseFloat(e.target.value))}
                required
              />
            </Form.Field>
            <Form.Field>
              <label>Money Put In</label>
              <Input
                type="number"
                step="0.01"
                value={moneyIn}
                onChange={(e) => setMoneyIn(parseFloat(e.target.value))}
                required
              />
            </Form.Field>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setShowEditModal(false)}>
            <Icon name="remove" /> Cancel
          </Button>
          <Button primary onClick={handleEditStock}>
            <Icon name="checkmark" /> Save
          </Button>
        </Modal.Actions>
      </Modal>
    </Container>
  );
};

export default StocksPage;
