import React, { useState, useEffect, useCallback } from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { Container, Grid, Segment, Form, Button, Table, Header, Message, Input, Dropdown } from 'semantic-ui-react';
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ArcElement);

const FinancePage = () => {
  const [income, setIncome] = useState('');
  const [expense, setExpense] = useState('');
  const [expenses, setExpenses] = useState([]);
  const [finances, setFinances] = useState([]);
  const [message, setMessage] = useState('');
  const [incomeDate, setIncomeDate] = useState('');
  const [expenseDate, setExpenseDate] = useState('');
  const [expenseCategory, setExpenseCategory] = useState('');
  const [expenseDescription, setExpenseDescription] = useState('');
  const [searchMonth, setSearchMonth] = useState('');
  const [searchYear, setSearchYear] = useState('');
  const [searchCategory, setSearchCategory] = useState('');
  const [pieChartMonth, setPieChartMonth] = useState('');
  const [pieChartYear, setPieChartYear] = useState('');
  const [error, setError] = useState('');
  const [currentPieChart, setCurrentPieChart] = useState('total');
  const [loading, setLoading] = useState(false);

  const categoriesList = [
    'Housing', 'Transportation', 'Utilities', 'Food/Supplies', 'Entertainment',
    'Healthcare', 'Insurance', 'Personal care', 'Debt', 'Gifts', 'Clothing', 'Donations'
  ];

  const categoryOptions = categoriesList.map(category => ({
    key: category,
    text: category,
    value: category,
  }));

  const generatePieChartData = useCallback(() => {
    const totalIncome = finances.reduce((sum, finance) => sum + finance.income, 0);
    const totalExpenses = finances.reduce((sum, finance) => sum + finance.expenses.reduce((expenseSum, expense) => expenseSum + expense.amount, 0), 0);
    const totalSavings = totalIncome - totalExpenses;

    return {
      labels: ['Expenses', 'Savings'],
      datasets: [
        {
          data: [totalExpenses, totalSavings],
          backgroundColor: ['#FF9999', '#99CCFF'],
        },
      ],
    };
  }, [finances]);

  const generateMonthlyPieChartData = useCallback((month, year) => {
    const filteredFinances = finances.filter(finance => {
      const financeDate = new Date(finance.date);
      return (
        (month ? financeDate.getMonth() + 1 === parseInt(month) : true) &&
        (year ? financeDate.getFullYear() === parseInt(year) : true)
      );
    });

    const expensesByCategory = categoriesList.map(category => {
      const total = filteredFinances.reduce((sum, finance) => {
        return sum + finance.expenses.filter(expense => expense.category === category)
          .reduce((categorySum, expense) => categorySum + expense.amount, 0);
      }, 0);
      return total;
    });

    const backgroundColors = [
      '#FF9999', '#99CCFF', '#FFCC99', '#CCFF99', '#FF99CC', '#99FFCC',
      '#CC99FF', '#CCCC99', '#99CC99', '#FF9966', '#6699FF'
    ];

    return {
      labels: categoriesList,
      datasets: [
        {
          data: expensesByCategory,
          backgroundColor: backgroundColors,
        },
      ],
    };
  }, [finances, categoriesList]);

  const pieChartOptions = {
    responsiv:true,
    maintainAspectRatio: true,
    plugins: {
      tooltip: {
        callbacks: {
          label: function (context) {
            const total = context.dataset.data.reduce((acc, val) => acc + val, 0);
            const value = context.raw;
            const percentage = ((value / total) * 100).toFixed(2);
            return `${context.label}: ${percentage}% (${value})`;
          },
        },
      },
    },
  };

  useEffect(() => {
    let isMounted = true;

    const fetchFinances = async () => {
      setLoading(true);
      try {
        const token = localStorage.getItem('token');
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': token,
          },
        };

        const response = await axiosInstance.get(`/finance`, config);
        if (isMounted) {
          setFinances(response.data || []);
        }
      } catch (error) {
        if (isMounted) {
          console.error('Error fetching finance data:', error);
          setMessage('Failed to fetch finance data');
          setError('Failed to fetch finance data');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchFinances();

    return () => {
      isMounted = false;
    };
  }, []);

  const handleSaveIncome = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      };

      await axiosInstance.post(
        `/finance/income`,
        { income: parseFloat(income).toFixed(2), date: incomeDate },
        config
      );
      setMessage('Income data saved successfully');
      setTimeout(() => setMessage(''), 3000);
      setIncome('');
      setIncomeDate('');
    } catch (err) {
      console.error('Failed to save income data:', err);
      setMessage('Failed to save income data');
      setError('Failed to save income data');
      setTimeout(() => setMessage(''), 3000);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveExpenses = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      };

      await axiosInstance.post(
        `/finance/expenses`,
        { expenses, date: expenseDate },
        config
      );
      setMessage('Expenses data saved successfully');
      setTimeout(() => setMessage(''), 3000);
      setExpenses([]);
      setExpenseDate('');
    } catch (err) {
      console.error('Failed to save expenses data:', err);
      setMessage('Failed to save expenses data');
      setError('Failed to save expenses data');
      setTimeout(() => setMessage(''), 3000);
    } finally {
      setLoading(false);
    }
  };

  const handleEditFinance = (id, field, value) => {
    setFinances(finances.map(finance => (
      finance._id === id ? { ...finance, [field]: value } : finance
    )));
  };

  const handleDeleteFinance = async (id) => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      };

      await axiosInstance.delete(`/finance/${id}`, config);
      setFinances(finances.filter(finance => finance._id !== id));
      setMessage('Finance entry deleted successfully');
      setTimeout(() => setMessage(''), 3000);
    } catch (err) {
      console.error('Failed to delete finance entry:', err);
      setMessage('Failed to delete finance entry');
      setError('Failed to delete finance entry');
      setTimeout(() => setMessage(''), 3000);
    } finally {
      setLoading(false);
    }
  };

  const generateChartData = () => {
    const labels = (finances || []).map(finance => new Date(finance.date).toLocaleDateString());
    const incomeData = (finances || []).map(finance => finance.income);
    const expenseData = (finances || []).map(finance => finance.expenses.reduce((sum, expense) => sum + expense.amount, 0));
    const savingsData = (finances || []).map(finance => finance.income - finance.expenses.reduce((sum, expense) => sum + expense.amount, 0));

    return {
      labels,
      datasets: [
        {
          label: 'Income',
          data: incomeData,
          backgroundColor: '#99CC99',
        },
        {
          label: 'Expenses',
          data: expenseData,
          backgroundColor: '#FF9999',
        },
        {
          label: 'Savings',
          data: savingsData,
          backgroundColor: '#99CCFF',
        },
      ],
    };
  };

  const filteredFinances = (finances || []).filter(finance => {
    const financeDate = new Date(finance.date);
    const monthMatches = searchMonth ? financeDate.getMonth() + 1 === parseInt(searchMonth) : true;
    const yearMatches = searchYear ? financeDate.getFullYear() === parseInt(searchYear) : true;
    const categoryMatches = searchCategory ? finance.expenses.some(exp => exp.category === searchCategory) : true;
    return monthMatches && yearMatches && categoryMatches;
  });

  return (
    <Container fluid className="finance-page ui container" style={{ marginTop: '70px' }}>
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <Header as="h2">Personal Finance</Header>
            {message && <Message positive>{message}</Message>}
            {error && <Message negative>{error}</Message>}
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={8}>
            <Segment>
              <Header as="h3">Monthly Summary</Header>
              {loading && <div className="ui active inline loader"></div>}
              <Form>
                <Form.Group widths="equal">
                  <Form.Input
                    fluid
                    label="Income Date"
                    type="date"
                    value={incomeDate}
                    onChange={(e) => setIncomeDate(e.target.value)}
                  />
                  <Form.Input
                    fluid
                    label="Monthly Income"
                    type="number"
                    step="0.01"
                    value={income}
                    onChange={(e) => setIncome(e.target.value)}
                  />
                </Form.Group>
                <Button primary onClick={handleSaveIncome}>Save Income</Button>

                <Form.Group widths="equal" className="mt-3">
                  <Form.Input
                    fluid
                    label="Expense Date"
                    type="date"
                    value={expenseDate}
                    onChange={(e) => setExpenseDate(e.target.value)}
                  />
                  <Form.Input
                    fluid
                    label="Expense Amount"
                    type="number"
                    step="0.01"
                    value={expense}
                    onChange={(e) => setExpense(e.target.value)}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <Form.Dropdown
                    fluid
                    label="Category"
                    selection
                    options={categoryOptions}
                    value={expenseCategory}
                    onChange={(e, { value }) => setExpenseCategory(value)}
                  />
                  <Form.Input
                    fluid
                    label="Description"
                    type="text"
                    value={expenseDescription}
                    onChange={(e) => setExpenseDescription(e.target.value)}
                  />
                </Form.Group>
                <Button primary onClick={handleSaveExpenses}>Save Expenses</Button>
              </Form>
            </Segment>

            <Segment className="mt-4">
              <Header as="h3">Search Finance Entries</Header>
              <Form>
                <Form.Group widths="equal">
                  <Form.Input
                    fluid
                    label="Month"
                    type="number"
                    value={searchMonth}
                    onChange={(e) => setSearchMonth(e.target.value)}
                    placeholder="MM"
                  />
                  <Form.Input
                    fluid
                    label="Year"
                    type="number"
                    value={searchYear}
                    onChange={(e) => setSearchYear(e.target.value)}
                    placeholder="YYYY"
                  />
                </Form.Group>
                <Form.Dropdown
                  fluid
                  label="Category"
                  selection
                  options={categoryOptions}
                  value={searchCategory}
                  onChange={(e, { value }) => setSearchCategory(value)}
                  placeholder="All Categories"
                />
              </Form>
            </Segment>
          </Grid.Column>

          <Grid.Column width={8}>
            <div className="chart-buttons">
              <Button primary onClick={() => setCurrentPieChart('total')}>Total Pie Chart</Button>
              <Button primary onClick={() => setCurrentPieChart('monthly')}>Monthly Pie Chart</Button>
            </div>
            {currentPieChart === 'monthly' && (
              <div className="pie-chart-controls">
                <Form>
                  <Form.Group>
                    <Form.Field width={8}>
                      <label>Month</label>
                      <Input
                        type="number"
                        value={pieChartMonth}
                        onChange={(e) => setPieChartMonth(e.target.value)}
                        placeholder="MM"
                      />
                    </Form.Field>
                    <Form.Field width={8}>
                      <label>Year</label>
                      <Input
                        type="number"
                        value={pieChartYear}
                        onChange={(e) => setPieChartYear(e.target.value)}
                        placeholder="YYYY"
                      />
                    </Form.Field>
                  </Form.Group>
                </Form>
              </div>
            )}
            <div className="pie-chart-wrapper">
              {currentPieChart === 'total' && (
                <Pie data={generatePieChartData()} options={pieChartOptions} />
              )}
              {currentPieChart === 'monthly' && (
                <Pie data={generateMonthlyPieChartData(pieChartMonth, pieChartYear)} options={pieChartOptions} />
              )}
            </div>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
            <div className="chart-wrapper">
              <Bar data={generateChartData()} options={{ maintainAspectRatio: true }} />
            </div>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row>
          <Grid.Column width={16}>
            <Header as="h3">All Finance Entries</Header>
            <div className="table-wrapper">
              <Table celled striped>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <Table.HeaderCell>Income</Table.HeaderCell>
                    <Table.HeaderCell>Expenses</Table.HeaderCell>
                    <Table.HeaderCell>Savings</Table.HeaderCell>
                    <Table.HeaderCell>Actions</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {filteredFinances.map(finance => (
                    <Table.Row key={finance._id}>
                      <Table.Cell>{new Date(finance.date).toLocaleDateString()}</Table.Cell>
                      <Table.Cell>
                        <Input
                          type="number"
                          step="0.01"
                          value={finance.income}
                          onChange={(e) => handleEditFinance(finance._id, 'income', parseFloat(e.target.value))}
                        />
                      </Table.Cell>
                      <Table.Cell>
                        {finance.expenses.map((expense, index) => (
                          <div key={index} className="expense-item">
                            <Input
                              type="text"
                              value={expense.category}
                              onChange={(e) => handleEditFinance(finance._id, `expenses[${index}].category`, e.target.value)}
                            />
                            <Input
                              type="text"
                              value={expense.description}
                              onChange={(e) => handleEditFinance(finance._id, `expenses[${index}].description`, e.target.value)}
                            />
                            <Input
                              type="number"
                              step="0.01"
                              value={expense.amount}
                              onChange={(e) => handleEditFinance(finance._id, `expenses[${index}].amount`, parseFloat(e.target.value))}
                            />
                          </div>
                        ))}
                      </Table.Cell>
                      <Table.Cell>
                        {finance.income - finance.expenses.reduce((sum, expense) => sum + expense.amount, 0)}
                      </Table.Cell>
                      <Table.Cell>
                        <Button basic color="red" onClick={() => handleDeleteFinance(finance._id)}>Delete</Button>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default FinancePage;
