import React, { useState, useEffect } from 'react';
import { Container, Grid, Segment, Form, Button, Header, Message, Input, TextArea, Icon, Modal } from 'semantic-ui-react';
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const EmailPage = () => {
  const [inbox, setInbox] = useState([]);
  const [showCompose, setShowCompose] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [currentEmail, setCurrentEmail] = useState(null);
  const [to, setTo] = useState('');
  const [subject, setSubject] = useState('');
  const [body, setBody] = useState('');
  const [message, setMessage] = useState('');
  const [creatingEmail, setCreatingEmail] = useState(true);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    const fetchEmails = async () => {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      };

      const inboxResponse = await axiosInstance.get(`/email/inbox`, config);
      setInbox(inboxResponse.data);
    };

    fetchEmails();
  }, []);

  const handleSend = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      };

      await axiosInstance.post(
        `/email/send`,
        { to, subject, body },
        config
      );
      setMessage('Email sent successfully');
      setTimeout(() => setMessage(''), 3000);
      setTo('');
      setSubject('');
      setBody('');
      setShowCompose(false);
    } catch (err) {
      setMessage('Failed to send email');
      setTimeout(() => setMessage(''), 3000);
    }
  };

  const handleEmailClick = (email) => {
    setCurrentEmail(email);
    setShowEmail(true);
  };

  const handleDownloadAttachment = (attachment) => {
    // Logic to download attachment
  };

  const handleCreateEmail = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      };

      await axiosInstance.post(
        `api/email/create`,
        { email, password },
        config
      );
      setMessage('Email account created successfully');
      setCreatingEmail(false);
    } catch (err) {
      setMessage('Failed to create email account');
      setTimeout(() => setMessage(''), 3000);
    }
  };

  return (
    <Container fluid className="email-pageui container" style={{ marginTop: '70px' }}>
      {creatingEmail ? (
        <Modal open={creatingEmail} size="small" onClose={() => setCreatingEmail(false)}>
          <Header icon="mail" content="Create Email Account" />
          <Modal.Content>
            <Form onSubmit={handleCreateEmail}>
              <Form.Field>
                <label>Email:</label>
                <Input
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </Form.Field>
              <Form.Field>
                <label>Password:</label>
                <Input
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </Form.Field>
              <Button type="submit" color="green">
                <Icon name="checkmark" /> Create
              </Button>
              {message && <Message>{message}</Message>}
            </Form>
          </Modal.Content>
        </Modal>
      ) : (
        <Grid>
          <Grid.Row>
            <Grid.Column width={4}>
              <Button primary onClick={() => setShowCompose(true)}>
                <Icon name="edit" /> Compose Email
              </Button>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <Segment>
                <Header as="h3">Inbox</Header>
                <div className="email-list">
                  {inbox.map((email) => (
                    <Segment key={email._id} className="email-item" onClick={() => handleEmailClick(email)}>
                      <p><strong>From:</strong> {email.from}</p>
                      <p><strong>Subject:</strong> {email.subject}</p>
                      {email.hasAttachment && <span className="attachment-icon">📎</span>}
                      <p><strong>Date:</strong> {email.date}</p>
                    </Segment>
                  ))}
                </div>
              </Segment>
            </Grid.Column>
            <Grid.Column width={12}>
              {showCompose && (
                <Modal open={showCompose} onClose={() => setShowCompose(false)}>
                  <Header icon="edit" content="Compose Email" />
                  <Modal.Content>
                    <Form onSubmit={handleSend}>
                      <Form.Field>
                        <label>To:</label>
                        <Input
                          type="text"
                          value={to}
                          onChange={(e) => setTo(e.target.value)}
                          required
                        />
                      </Form.Field>
                      <Form.Field>
                        <label>Subject:</label>
                        <Input
                          type="text"
                          value={subject}
                          onChange={(e) => setSubject(e.target.value)}
                          required
                        />
                      </Form.Field>
                      <Form.Field>
                        <label>Body:</label>
                        <TextArea
                          value={body}
                          onChange={(e) => setBody(e.target.value)}
                          required
                        />
                      </Form.Field>
                      <Button type="submit" color="green">
                        <Icon name="send" /> Send
                      </Button>
                      <Button type="button" color="red" onClick={() => setShowCompose(false)}>
                        <Icon name="cancel" /> Cancel
                      </Button>
                      {message && <Message>{message}</Message>}
                    </Form>
                  </Modal.Content>
                </Modal>
              )}

              {showEmail && (
                <Modal open={showEmail} onClose={() => setShowEmail(false)}>
                  <Header icon="mail" content="Email Details" />
                  <Modal.Content>
                    <p><strong>From:</strong> {currentEmail.from}</p>
                    <p><strong>Subject:</strong> {currentEmail.subject}</p>
                    <p><strong>Body:</strong> {currentEmail.body}</p>
                    <p><strong>Date:</strong> {currentEmail.date}</p>
                    {currentEmail.hasAttachment && (
                      <div>
                        <Header as="h5">Attachments:</Header>
                        {currentEmail.attachments.map((attachment, index) => (
                          <p key={index} onClick={() => handleDownloadAttachment(attachment)}>
                            {attachment}
                          </p>
                        ))}
                      </div>
                    )}
                  </Modal.Content>
                  <Modal.Actions>
                    <Button color="blue" onClick={() => setShowEmail(false)}>
                      <Icon name="checkmark" /> Close
                    </Button>
                  </Modal.Actions>
                </Modal>
              )}
            </Grid.Column>
          </Grid.Row>
        </Grid>
      )}
    </Container>
  );
};

export default EmailPage;
