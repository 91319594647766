import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Dashboard from './components/Dashboard.jsx';
import PersonalInfo from './components/PersonalInfo.jsx';
import EmailPage from './components/EmailPage.jsx';
import FinancePage from './components/FinancePage.jsx';
import Register from './components/Register.jsx';
import Login from './components/Login.jsx';
import PrivateRoute from './components/PrivateRoute.jsx';
import Navbar from './components/Navbar.jsx';
import NotesPage from './components/NotesPage.jsx'; // Import NotesPage
import StocksPage from './components/StocksPage.jsx'; // Import StocksPage
import CryptoPage from './components/CryptosPage.jsx'; // Import CryptoPage

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <Routes>
          <Route path="/" element={<Dashboard />} />
          <Route path="/register" element={<Register />} />
          <Route path="/login" element={<Login />} />
          <Route
            path="/personal-info"
            element={
              <PrivateRoute>
                <PersonalInfo />
              </PrivateRoute>
            }
          />
          <Route
            path="/email"
            element={
              <PrivateRoute>
                <EmailPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/finance"
            element={
              <PrivateRoute>
                <FinancePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/notes"
            element={
              <PrivateRoute>
                <NotesPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/stocks"
            element={
              <PrivateRoute>
                <StocksPage />
              </PrivateRoute>
            }
          />           
          <Route
            path="/crypto"
            element={
            <PrivateRoute>
              <CryptoPage />
            </PrivateRoute>
          }
        /> {/* Add this line */}
        </Routes>
        
      </div>
    </Router>
  );
}

export default App;
