import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Grid, Segment, Form, Button, Header, Message, Input } from 'semantic-ui-react';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const PersonalInfo = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [passwordMessage, setPasswordMessage] = useState('');
  const [form, setForm] = useState({
    name: '',
    backupEmail: '',
    nationalInsurance: '',
    vehiclePlate: '',
    homeStreet: '',
    homeCouncil: '',
    homePostcode: '',
    workStreet: '',
    workCouncil: '',
    workPostcode: '',
  });
  const [passwordForm, setPasswordForm] = useState({
    oldPassword: '',
    newPassword: '',
  });

  useEffect(() => {
    const fetchUserInfo = async () => {
      try {
        const token = localStorage.getItem('token');
        const config = {
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': token,
          },
        };

        const response = await axiosInstance.get(`/auth/me`, config);
        setUserInfo(response.data);
        setForm({
          name: response.data.name,
          backupEmail: response.data.backupEmail,
          nationalInsurance: response.data.nationalInsurance,
          vehiclePlate: response.data.vehiclePlate,
          homeStreet: response.data.homeAddress.street,
          homeCouncil: response.data.homeAddress.council,
          homePostcode: response.data.homeAddress.postcode,
          workStreet: response.data.workAddress.street,
          workCouncil: response.data.workAddress.council,
          workPostcode: response.data.workAddress.postcode,
        });
        setLoading(false);
      } catch (err) {
        setError('Failed to fetch user info');
        setLoading(false);
      }
    };

    fetchUserInfo();
  }, []);

  const handleChange = (e) => {
    setForm({ ...form, [e.target.name]: e.target.value });
  };

  const handlePasswordChange = (e) => {
    setPasswordForm({ ...passwordForm, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      };

      const response = await axiosInstance.put(
        `/auth/me`,
        {
          name: form.name,
          backupEmail: form.backupEmail,
          nationalInsurance: form.nationalInsurance,
          vehiclePlate: form.vehiclePlate,
          homeAddress: {
            street: form.homeStreet,
            council: form.homeCouncil,
            postcode: form.homePostcode,
          },
          workAddress: {
            street: form.workStreet,
            council: form.workCouncil,
            postcode: form.workPostcode,
          },
        },
        config
      );
      setUserInfo(response.data);
      setSuccessMessage('Information updated successfully');
      setTimeout(() => setSuccessMessage(''), 3000);
    } catch (err) {
      setError('Failed to update information');
      setTimeout(() => setError(''), 3000);
    }
  };

  const handlePasswordSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token');
      const config = {
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      };

      await axiosInstance.put(
        `/auth/me/password`,
        {
          oldPassword: passwordForm.oldPassword,
          newPassword: passwordForm.newPassword,
        },
        config
      );
      setPasswordMessage('Password updated successfully');
      setTimeout(() => setPasswordMessage(''), 3000);
    } catch (err) {
      setPasswordMessage('Failed to update password');
      setTimeout(() => setPasswordMessage(''), 3000);
    }
  };

  if (loading) {
    return <p>Fetching user info...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <Container className="personal-info-page ui container" style={{ marginTop: '70px' }}>
      <Header as="h1" className="page-title">Personal Information</Header>
      <Form className="personal-info-form" onSubmit={handleSubmit}>
        <Segment>
          <Header as="h2">User Information</Header>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Username</label>
              <Input type="text" value={userInfo.username} readOnly />
            </Form.Field>
            <Form.Field>
              <label>Name</label>
              <Input
                type="text"
                name="name"
                value={form.name}
                onChange={handleChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Backup Email</label>
              <Input
                type="email"
                name="backupEmail"
                value={form.backupEmail}
                onChange={handleChange}
              />
            </Form.Field>
            <Form.Field>
              <label>National Insurance Number</label>
              <Input
                type="text"
                name="nationalInsurance"
                value={form.nationalInsurance}
                onChange={handleChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Vehicle Plate</label>
              <Input
                type="text"
                name="vehiclePlate"
                value={form.vehiclePlate}
                onChange={handleChange}
              />
            </Form.Field>
          </Form.Group>
        </Segment>
        <Segment>
          <Header as="h2">Home Address</Header>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Street</label>
              <Input
                type="text"
                name="homeStreet"
                value={form.homeStreet}
                onChange={handleChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Council</label>
              <Input
                type="text"
                name="homeCouncil"
                value={form.homeCouncil}
                onChange={handleChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Postcode</label>
              <Input
                type="text"
                name="homePostcode"
                value={form.homePostcode}
                onChange={handleChange}
              />
            </Form.Field>
          </Form.Group>
        </Segment>
        <Segment>
          <Header as="h2">Work Address</Header>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Street</label>
              <Input
                type="text"
                name="workStreet"
                value={form.workStreet}
                onChange={handleChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Council</label>
              <Input
                type="text"
                name="workCouncil"
                value={form.workCouncil}
                onChange={handleChange}
              />
            </Form.Field>
            <Form.Field>
              <label>Postcode</label>
              <Input
                type="text"
                name="workPostcode"
                value={form.workPostcode}
                onChange={handleChange}
              />
            </Form.Field>
          </Form.Group>
        </Segment>
        <Button type="submit" color="green">Update Information</Button>
        {successMessage && <Message positive>{successMessage}</Message>}
      </Form>
      <Form className="personal-info-form" onSubmit={handlePasswordSubmit}>
        <Segment>
          <Header as="h2">Change Password</Header>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Old Password</label>
              <Input
                type="password"
                name="oldPassword"
                value={passwordForm.oldPassword}
                onChange={handlePasswordChange}
              />
            </Form.Field>
            <Form.Field>
              <label>New Password</label>
              <Input
                type="password"
                name="newPassword"
                value={passwordForm.newPassword}
                onChange={handlePasswordChange}
              />
            </Form.Field>
          </Form.Group>
          <Button type="submit" color="green">Change Password</Button>
          {passwordMessage && <Message positive>{passwordMessage}</Message>}
        </Segment>
      </Form>
    </Container>
  );
};

export default PersonalInfo;
