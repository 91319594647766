import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Form, Button, Header, Segment, Message } from 'semantic-ui-react';
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const Register = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [name, setName] = useState('');
  const [backupEmail, setBackupEmail] = useState('');
  const [nationalInsurance, setNationalInsurance] = useState('');
  const [vehiclePlate, setVehiclePlate] = useState('');
  const [homeStreet, setHomeStreet] = useState('');
  const [homeCouncil, setHomeCouncil] = useState('');
  const [homePostcode, setHomePostcode] = useState('');
  const [workStreet, setWorkStreet] = useState('');
  const [workCouncil, setWorkCouncil] = useState('');
  const [workPostcode, setWorkPostcode] = useState('');
  const [message, setMessage] = useState('');
  const [buttonClass, setButtonClass] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await axiosInstance.post(`/auth/register`, {
        username,
        password,
        name,
        backupEmail,
        nationalInsurance,
        vehiclePlate,
        homeAddress: { street: homeStreet, council: homeCouncil, postcode: homePostcode },
        workAddress: { street: workStreet, council: workCouncil, postcode: workPostcode },
      });
      setButtonClass('leaf-green');
      setMessage('Registration successful. You can now login.');
      setTimeout(() => {
        setButtonClass('');
        navigate('/login');
      }, 2000);
    } catch (error) {
      setButtonClass('tomato');
      setMessage('Registration failed. Please try again.');
      setTimeout(() => setButtonClass(''), 2000);
    }
  };

  return (
    <Container className="auth-page">
      <Segment textAlign="center">
        <Header as="h1" className="page-title">Register</Header>
        <Form onSubmit={handleSubmit} className="personal-info-form">
          <Segment className="box">
            <Form.Group widths="equal">
              <Form.Input
                label="Username"
                type="text"
                name="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              <Form.Input
                label="Password"
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                label="Name"
                type="text"
                name="name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
              <Form.Input
                label="Backup Email"
                type="email"
                name="backupEmail"
                value={backupEmail}
                onChange={(e) => setBackupEmail(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Input
                label="National Insurance Number"
                type="text"
                name="nationalInsurance"
                value={nationalInsurance}
                onChange={(e) => setNationalInsurance(e.target.value)}
                required
              />
              <Form.Input
                label="Vehicle Plate"
                type="text"
                name="vehiclePlate"
                value={vehiclePlate}
                onChange={(e) => setVehiclePlate(e.target.value)}
                required
              />
            </Form.Group>
          </Segment>
          <Segment className="box">
            <Header as="h2">Home Address</Header>
            <Form.Group widths="equal">
              <Form.Input
                label="Street"
                type="text"
                name="homeStreet"
                value={homeStreet}
                onChange={(e) => setHomeStreet(e.target.value)}
                required
              />
              <Form.Input
                label="Council"
                type="text"
                name="homeCouncil"
                value={homeCouncil}
                onChange={(e) => setHomeCouncil(e.target.value)}
                required
              />
              <Form.Input
                label="Postcode"
                type="text"
                name="homePostcode"
                value={homePostcode}
                onChange={(e) => setHomePostcode(e.target.value)}
                required
              />
            </Form.Group>
          </Segment>
          <Segment className="box">
            <Header as="h2">Work Address</Header>
            <Form.Group widths="equal">
              <Form.Input
                label="Street"
                type="text"
                name="workStreet"
                value={workStreet}
                onChange={(e) => setWorkStreet(e.target.value)}
                required
              />
              <Form.Input
                label="Council"
                type="text"
                name="workCouncil"
                value={workCouncil}
                onChange={(e) => setWorkCouncil(e.target.value)}
                required
              />
              <Form.Input
                label="Postcode"
                type="text"
                name="workPostcode"
                value={workPostcode}
                onChange={(e) => setWorkPostcode(e.target.value)}
                required
              />
            </Form.Group>
          </Segment>
          <Button type="submit" className={buttonClass}>Register</Button>
          {message && <Message color={buttonClass === 'tomato' ? 'red' : 'green'}>{message}</Message>}
        </Form>
      </Segment>
    </Container>
  );
};

export default Register;
