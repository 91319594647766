import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Form, Button, Header, Segment, Message } from 'semantic-ui-react';
import axios from 'axios';

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [buttonClass, setButtonClass] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post(`/auth/login`, { username, password });
      localStorage.setItem('token', response.data.token);
      setButtonClass('leaf-green');
      setMessage('Login successful');
      setTimeout(() => {
        setButtonClass('');
        navigate('/');
      }, 2000);
    } catch (error) {
      setButtonClass('tomato');
      setMessage('Login failed. Please check your credentials and try again.');
      setTimeout(() => setButtonClass(''), 2000);
    }
  };

  return (
    <Container className="auth-page">
      <Segment textAlign="center">
        <Header as="h1">Login</Header>
        <Form onSubmit={handleSubmit} className="auth-form">
          <Form.Field>
            <label>Username</label>
            <input
              type="text"
              name="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
              required
            />
          </Form.Field>
          <Form.Field>
            <label>Password</label>
            <input
              type="password"
              name="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </Form.Field>
          <Button type="submit" className={buttonClass}>Login</Button>
          {message && <Message color={buttonClass === 'tomato' ? 'red' : 'green'}>{message}</Message>}
        </Form>
      </Segment>
    </Container>
  );
};

export default Login;